import { Link } from "gatsby"
import React from "react"
import ViberLogo from "../svg/viber.svg"
import WALogo from "../svg/whatsapp.svg"
import MTSLogo from "../svg/mts.svg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <header
      style={{
        textAlign: "center",
        position: "fixed",
        top: "0",
        right: "0",
        left: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "1300px",
          padding: "0 30px",
          width: "100%",
          margin: "auto",
        }}
      >
        <Link to="/">
          <div>
            <Img
              style={{ height: "4em", width: "4em", display: "inline-block" }}
              fluid={data.placeholderImage.childImageSharp.fluid}
            />
          </div>
        </Link>
        <div className="links">
          <Link to="/#services">Услуги</Link>
          <Link to="/#about">О нас</Link>
          <Link to="/#works">Портфолио</Link>
          <Link to="/#reviews">Отзывы</Link>
          <Link to="/#feedback">Оставить заявку</Link>
        </div>
        <a
          style={{
            color: "var(--color)",
            fontSize: "1.3em",
            fontWeight: "700",
          }}
          href="tel:+375295777733"
        >
          +375 29 577-77-
          <span style={{ color: "var(--second-color)" }}>33</span>
          <MTSLogo style={{height:'0.9em',width:'0.9em',transform:'translate(0px, 2px)',marginLeft:'0.3em'}}></MTSLogo>
          <ViberLogo style={{height:'0.9em',width:'0.9em',transform:'translate(0px, 2px)',marginLeft:'0.3em'}}></ViberLogo>
          <WALogo style={{height:'0.9em',width:'0.9em',transform:'translate(0px, 1px) scale(0.9)',marginLeft:'0.3em'}}></WALogo>
        </a>
      </div>
    </header>
  )
}

export default Header

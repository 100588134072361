import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "./layout.css"
import VkSVG from "../svg/vk.svg"
import MapSVG from "../svg/map.svg"
import InstagramSVG from "../svg/instagram.svg"
import FacebookSVG from "../svg/facebook.svg"
import ClockSVG from "../svg/clock.svg"
import PhoneSVG from "../svg/phone.svg"
import MailSVG from "../svg/mail.svg"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>
        <main>{children}</main>
        <section className="contacts">
          <h3
            className="centered-heading"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out"
            data-sal-duration="500ms"
          >
            Контакты
            <p>Не теряйте время и свяжитесь с нами</p>
          </h3>
          <div className="wrap">
            <div
              className="footer-contacts"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
              data-sal-duration="500ms"
            >
              <div>
                <h5>Время работы</h5>
                <p><ClockSVG/>Без выходных 24/7</p>
              </div>
              <div>
                <h5>Ищите нас в</h5>
                <p className="social" style={{color: "var(--color)"}}>
                  <a rel="nofollow" target="_blank" href="https://www.instagram.com/groomvit/">
                    <InstagramSVG/>
                  </a>
                  <a rel="nofollow" target="_blank" href="https://vk.com/club167344258">
                    <VkSVG/>
                  </a>
                  <a rel="nofollow" target="_blank" href="https://facebook.com/tata.romanova.7">
                    <FacebookSVG/>
                  </a>
                </p>
              </div>
              <div>
                <h5>Адрес</h5>
                <p><MapSVG/>Беларусь, г. Витебск</p>
              </div>
              <div>
                <h5>Контакты</h5>
                <p><MailSVG/>timosha20120204@gmail.com</p>
                <p><PhoneSVG/>+375 29 577-77-33</p>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="wrap">
            © {new Date().getFullYear()}, Сделано в{` `}
            <a target="_blank" rel="nofollow" href="https://snowdigital.by">
              SnowDigital
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
